import * as React from 'react';

import Img from 'gatsby-image';

import './ImplantationSingleCard.scss';
import { HorizontalYellowLineShape } from '../../Atoms/Icons/Icons';

let classNames = require('classnames');

const ImplantationSingleCard = ({ visuel, title, adresse, details, tel1, tel2, email, website }) => {

  return (
    <div className="implantation_single_card_component">
      <div className="visual">
        <div className="image_mask">
          <div className="mask image_container">
            {visuel &&
              <Img
                style={{ width: '100%' }}
                imgStyle={{ objectFit: 'cover' }}
                fluid={visuel?.fluid}
              />
            }
          </div>
        </div>
      </div>
      <div className="text">
        <p className="title">
          <span>
            {((title).length > 50) ?
              (((title).substring(0, 47)) + '...') :
              title}
          </span>
          <HorizontalYellowLineShape className="line" />
        </p>
        <p className="adresse">{adresse}</p>
        <p className="phone">
          {tel1 && 'Tel. ' + tel1}
          {tel2 && '<br />Tel. ' + tel2}
        </p>
        <p className="email">{email && email}</p>
        <p className="web">{website && website}</p>
      </div>
    </div>
  );
};

ImplantationSingleCard.default = {
  title: 'Default Title',
  adresse: 'Default Adresse',
  details: 'Tel. 01 00 00 00 00'
};

export default ImplantationSingleCard;
